import moment from 'moment';
import { orderBy } from 'lodash';
import {
  BeadMaterialType,
  DialCodes,
  GrindingTechnology,
  InnerLinerMaterial,
  MachineTypes,
  SensorTypes
} from 'web-components';

import { getIn } from 'formik';
import { useEffect } from 'react';
import { ERROR, IN_PROGRESS, INITIAL, SUCCESS } from '../attrs/status';
import { MACHINE_STATUS_MAP, SENSOR_MAP, SENSOR_STATUS_MAP, SENSOR_STATUS_NODATA } from '../attrs/notifications';
import { METRIC_UNITS, METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from '../attrs/layout';
import { ROLES } from '../attrs/roles';
import { defaultNullSensorValue } from '../redux/machines/constants';

const generateId = () => (new Date().getTime() + Math.random()).toString();

const isSuccess = status => status === SUCCESS;
const isLoading = status => status === IN_PROGRESS;
const isError = status => status === ERROR;

const isNullOrUndefined = value => value === null || value === undefined;

const isNullUndefinedOrEmpty = value => value === null || value === undefined || value === '';

const getMachineStatusProps = status => MACHINE_STATUS_MAP.find(item => item.value === status) || MACHINE_STATUS_MAP[5];
const getSensorProps = value => SENSOR_MAP.find(item => item.value === value) || SENSOR_MAP[0];
const getSensorStatusProps = value => SENSOR_STATUS_MAP.find(item => item.value === value) || SENSOR_STATUS_MAP[0];

const getMachineType = value => MachineTypes.find(item => item.value === value) || MachineTypes[0];

const getMachineErrorTimeFrame = updatedAt => {
  const to = moment().toISOString();
  const from = moment(updatedAt).subtract(24, 'hours').toISOString();

  return { from, to };
};

const getListWithoutDefaultValue = list => list.filter(data => data.value !== 'UNKNOWN' && data.value !== null);

const getMillGrindingTechnology = value =>
  GrindingTechnology.find(item => item.value === value) || GrindingTechnology[0];

const getMillInnerLinerMaterial = value =>
  InnerLinerMaterial.find(item => item.value === value) || InnerLinerMaterial[0];

const getMillBeadMaterialType = value => BeadMaterialType.find(item => item.value === value) || BeadMaterialType[0];

const getMetricsTimeFrame = (range = METRICS_DEFAULT_TIME_FRAME_IN_HOURS) => {
  const to = moment().toISOString();
  const from = moment(to).subtract(range, 'hours').toISOString();

  return { from, to };
};

const getBatchRecordTimeFrame = (start, end) => {
  const gap = 30;
  const from = moment(start).subtract(gap, 'minutes').toISOString();
  const to = moment(end).add(gap, 'minutes').toISOString();

  return { from, to };
};

const getSensorPropsFromType = type => {
  if (!type) {
    return {};
  }

  const typeArray = type.split('_');
  const machineIdent = typeArray[0];

  // TODO
  // Just temporary -  We check for the sensor ident number: _00 default
  const sensorIdentNumber = typeArray[typeArray.length - 1];
  const withSensorSubIdent = sensorIdentNumber !== '00';

  const slicedArray = typeArray.slice(1, typeArray.length - 1);
  const machineSensorType =
    SensorTypes[machineIdent] && SensorTypes[machineIdent][slicedArray.join('_')]
      ? SensorTypes[machineIdent][slicedArray.join('_')]
      : null;

  const defaultProps = machineSensorType || SensorTypes.unknown;
  const subIdentProps = ((machineSensorType || {}).subIdent || {})[sensorIdentNumber] || {};

  const props = {
    ...defaultProps,
    ...(withSensorSubIdent && machineSensorType ? subIdentProps : {})
  };

  return {
    ...props,
    type,
    name: machineSensorType ? `sensors.${machineIdent}.${slicedArray.join('_')}` : 'sensors.unknown',
    status: SENSOR_STATUS_NODATA
  };
};

const getSensorPropsFromMetric = metric => {
  const ret = getSensorPropsFromType(metric.type);
  if (metric.is_custom || metric.is_static) {
    ret.is_custom = metric.is_custom;
    ret.is_static = metric.is_static;
    ret.type = metric.custom_type;
    ret.unit = metric.custom_unit;
    ret.name = metric.name;
    ret.custom_icon_type = metric.custom_icon_type;
    if ((metric.yAxisDomain || []).length > 0) {
      ret.visualization = {
        yMin: metric.yAxisDomain[0] || '0',
        yMax: metric.yAxisDomain[1] || 'auto'
      };
    }
  }
  return ret;
};

/**
 * Count the number of decimal places in a "0.00000.....1" string
 * @param {string} decimalPlaces "0.00000001","0.1", "0"
 * @returns {number}
 */
const getNDecimalPlaces = decimalPlaces => {
  if (decimalPlaces !== '0') {
    if (/0.(?<zeros_right>[0-1]*)/gm.test(decimalPlaces)) {
      const {
        groups: { zerosRight }
      } = /0.(?<zerosRight>[0-1]*)/gm.exec(decimalPlaces);
      return zerosRight.length;
    }
  }
  return 0;
};

const getRoundedValue = (value, n = 2, toNumber = true) => {
  if (typeof value !== 'number') {
    return value;
  }

  if (toNumber) {
    return Number(value.toFixed(n));
  }
  return value.toFixed(n);
};

/**
 * Return the number with the default decimal place, for custom sensors, use the decimal_place value
 * @param {Object<is_custom: boolean, decimal_place: string, multiplication_factor: number>} sensor
 * @param {number} value
 * @param {number} n
 * @param forceRoundFromParam
 * @returns {*|number}
 */
const getRoundedDecimalPlaceSensorValue = (sensor, value, n = 2, forceRoundFromParam = false) => {
  if (sensor.is_custom && !forceRoundFromParam) {
    const decimalPlaces = getNDecimalPlaces(sensor.is_custom ? sensor.decimal_place : '0.01');
    return getRoundedValue(value, decimalPlaces, false);
  }
  return getRoundedValue(value, n, false);
};

/**
 * This function takes a sensor data and an array of chart data as input,
 * and returns a new array of rounded chart data.
 *
 * @param {Object} sensorData - The array of sensor data.
 * @param {[{
 *     "activeStatus": String,
 *     "displayValue": String,
 *     "name": String,
 *     "time": number,
 *     "value": number,
 *     "warning": number
 * }]} chartData - The array of chart data.
 * @returns {Array} - The new array of rounded chart data.
 */
const getRoundedChartData = (sensorData, chartData) =>
  chartData.map(data => ({
    ...data,
    value: getRoundedDecimalPlaceSensorValue(sensorData, data.value, sensorData.decimalPlace)
  }));

const getUnit = (unit, sensorName) => {
  if (!isNullUndefinedOrEmpty(sensorName)) {
    if (sensorName.includes('sensors.mill.uptime')) {
      return '';
    }
  }
  return METRIC_UNITS[unit] || unit;
};

const getSensorValue = (sensor, value, n = null) => {
  if (value === defaultNullSensorValue) {
    return value;
  }

  // TODO: Validate this approach
  if ((sensor.type || '').includes('sensors.mill.uptime') && !isNullUndefinedOrEmpty(value)) {
    const ms = value * 1000;
    return Math.floor(moment.duration(ms).asHours()).toString().padStart(2, '0') + moment.utc(ms).format(':mm:ss');
  }
  let decimal;

  if (sensor.is_custom || sensor.is_static) {
    decimal = getNDecimalPlaces(sensor.decimal_place);
  } else {
    const { decimalPlace } = getSensorPropsFromType(sensor.type);
    decimal = isNullOrUndefined(sensor.decimalPlace) ? decimalPlace : sensor.decimalPlace;
  }

  return getRoundedDecimalPlaceSensorValue(sensor, value, n || decimal);
};

const getSensorValueGraph = (sensor, value, n) => {
  if (value === defaultNullSensorValue) {
    return value;
  }

  if (sensor.name === 'sensors.mill.uptime' && !isNullUndefinedOrEmpty(value)) {
    const ms = value * 1000;
    return moment.duration(ms).asHours();
  }

  return getRoundedDecimalPlaceSensorValue(sensor, value, n);
};

const getSensorValueYAxis = (sensor, value, n, forceRoundFromParam = false) => {
  if (value === defaultNullSensorValue) {
    return value;
  }

  if (sensor.name === 'sensors.mill.uptime' && !isNullUndefinedOrEmpty(value)) {
    const ms = value * 1000;
    return Math.floor(moment.duration(ms).asHours());
  }

  return getRoundedDecimalPlaceSensorValue(sensor, value, n, forceRoundFromParam);
};

const getSensorUnit = sensor => {
  if (sensor.is_custom) {
    return sensor.custom_unit;
  }
  return METRIC_UNITS[sensor.unit] || sensor.unit;
};

const getSensorName = (sensor, translatedName) => {
  if (sensor.is_custom) {
    return sensor.custom_name || sensor.name;
  }
  return translatedName;
};

const getActiveUsersAsOptions = data => {
  const users = data
    .filter(item => item.is_active && item.first_name && item.first_name !== '')
    .map(item => ({ title: `${item.first_name} ${item.last_name}`, value: `${item.id}` }));
  return orderBy(users, item => item.title, 'asc');
};

const defaultCheckOrder = [IN_PROGRESS, ERROR, INITIAL, SUCCESS];

const getCommonStatus = (parts, checkOrder = defaultCheckOrder) => {
  for (let i = 0; i < checkOrder.length; i += 1) {
    const status = checkOrder[i];
    if (parts.includes(status)) {
      return status;
    }
  }

  return null;
};

// get common ground of different states to prevent rendering without ready data
// e.g. if any status is "in progress", common status will be "in progress"
const getCommonLoadingState = parts => {
  const getStatus = entry => (entry ? entry.status : null);
  const commonStatus = getCommonStatus(parts.map(getStatus), defaultCheckOrder);

  return {
    status: commonStatus,
    errors: parts.filter(part => part.status === commonStatus).map(part => part.errors)
  };
};

const getUserRole = role => ROLES.find(item => role?.includes(item.value)) || ROLES[0];

const getDialCodeFromCountryCode = value =>
  (DialCodes.find(country => country.code === value) || {}).dial_code || DialCodes[0].dial_code;

const getEvery15Min = () => {
  const minutesInterval = 15; // minutes interval
  const times = [];
  let tt = 0;

  for (let i = 0; tt < 24 * 60; i += 1) {
    const hh = Math.floor(tt / 60); // getting hours
    const mm = tt % 60; // getting minutes

    times[i] = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}`;
    tt += minutesInterval;
  }

  return times;
};

// /////////////////// Formik Helpers

const getFormikError =
  ({ errors, touched }) =>
  name =>
    getIn(errors, name) && getIn(touched, name);

const getFormikHelperText =
  ({ errors, t }) =>
  name => {
    const error = getIn(errors, name);

    if (error) {
      return t(`form.validate.${error}`);
    }

    return '';
  };

const isCustomSensor = sensorName => {
  const customRegex = /custom/;
  const arrSensorName = sensorName.toLowerCase().match(customRegex);

  return Array.isArray(arrSensorName);
};

const isStaticSensor = sensorName => /^static_sensor_id_([\d]+)$/.test(sensorName);

const getCustomSensorId = sensorName => {
  const match = /^custom_sensor_id_([\d]+)$/.exec(sensorName);
  if (match) {
    if (match.length > 0) {
      const id = parseInt(match[1], 10);
      if (!Number.isNaN(id)) {
        return id;
      }
    }
  }
  return -1;
};

const getCustomSensorName = customSensorId => `custom_sensor_id_${customSensorId}`;

const getDefaultFieldValue = value => (!isNullOrUndefined(value) ? value : '');

const parseFloatDefault = value => {
  const num = parseFloat(value);
  return Number.isNaN(num) ? undefined : num;
};

const getChangeNumberHandler = setFieldValue => name => event => {
  const num = parseInt(event.target.value, 10);
  const value = Number.isNaN(num) ? undefined : num;

  return setFieldValue(name, value);
};

const getChangeFloatHandler = setFieldValue => name => event => {
  const value = parseFloatDefault(event.target.value);

  return setFieldValue(name, value);
};

const getUserFeature = (user, featureConst) => {
  let feature = null;
  if (!isNullUndefinedOrEmpty(user.features)) {
    if (Array.isArray(user.features)) {
      user.features.forEach(f => {
        if (featureConst === f.constant) {
          feature = f;
        }
      });
    }
  }
  return feature;
};

const getUserDateFormat = user => {
  switch (user.language) {
    case 'en_US':
      return 'MM/DD/YYYY';
    case 'de_DE':
    case 'pt_BR':
    case 'es_ES':
      return 'DD/MM/YYYY';
    case 'ja_JP':
      return 'YYYY/MM/DD';
    case 'zh_CN':
    case 'zh_TW':
      return 'YYYY-MM-DD';
    default:
      return 'MM/DD/YYYY';
  }
};

const concatDates = (date, time) => {
  const newDate = new Date(date);
  const newTime = new Date(time);
  newDate.setHours(newTime.getHours(), newTime.getMinutes(), newTime.getSeconds());
  return newDate.toISOString();
};

const isStringNumeric = str => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

const timeToString = value => {
  const ms = value * 1000;
  return Math.floor(moment.duration(ms).asHours()).toString().padStart(2, '0') + moment.utc(ms).format(':mm:ss');
};

const getInitials = name => {
  const nameSplit = name.split(' ');
  let initials = '';

  if (nameSplit.length === 1) {
    initials = name.charAt(0);
  } else {
    let firstInitial = '';
    let lastInitial = '';
    nameSplit.map((item, key) => {
      if (key === 0) {
        firstInitial = item.charAt(0);
      }
      if (nameSplit.length - 1 === key) {
        lastInitial = item.charAt(0);
      }
      initials = `${firstInitial}${lastInitial}`;
      return initials;
    });
  }
  return initials;
};

const getDecimalSeparator = () => {
  let n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
};

const getCurrentGMT = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  if (offset === 0) {
    return 'GMT';
  }
  const signal = offset > 0 ? '-' : '+';
  return `GMT${signal}${new Date(offset * 1000 * 60).toISOString().substring(11, 11 + 5)}`;
};

const getUserLocale = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Get the average value when a sensor has null value
 * @param {{
 *    chartData: Array,
 *    machineChartData: Array,
 *    sensors: Array,
 *    xAxisDomain: Array,
 *    xAxisTicks: Array
 *  }} dataResponse
 */
const getChartDataNullNormalize = dataResponse => {
  const metricsHistory = dataResponse;
  if (!isNullOrUndefined(metricsHistory) && !isNullOrUndefined(metricsHistory.chartData)) {
    metricsHistory.chartData.forEach((data, index) => {
      if (!isNullOrUndefined(data) && !isNullOrUndefined(data.labels)) {
        data.labels.forEach((label, labelIndex) => {
          if (label.value === null) {
            if (index > 0 && index < metricsHistory.chartData.length - 1) {
              const lastLabelValue = metricsHistory.chartData[index - 1].labels[labelIndex].value;
              const nextLabelValue = metricsHistory.chartData[index + 1].labels[labelIndex].value;
              if (lastLabelValue !== null && nextLabelValue !== null) {
                const sensor = metricsHistory.sensors.find(s => s.type === label.type);
                let decimalPlaces = 2;
                if (sensor && sensor.is_custom) {
                  decimalPlaces = getNDecimalPlaces(sensor.decimal_place);
                } else {
                  const predefinedSensor = getSensorPropsFromType(label.type);
                  if (predefinedSensor && predefinedSensor.decimalPlace) {
                    decimalPlaces = predefinedSensor.decimalPlace;
                  }
                }

                metricsHistory.chartData[index].labels[labelIndex].value = getRoundedValue(
                  (lastLabelValue + nextLabelValue) / 2,
                  decimalPlaces,
                  false
                );
              }
            }
          }
        });
      }
    });
  }
  return metricsHistory;
};

/**
 * Creates a deep copy of an object.
 *
 * @param {Object} obj - The object to be copied.
 * @returns {Object} - The deep copy of the object.
 */
function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Checks if two arrays are equal.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {boolean} Returns true if the arrays are equal, false otherwise.
 */
function arraysEqual(arr1, arr2) {
  return arr1?.length === arr2?.length && arr1?.every((value, index) => value === arr2[index]);
}

/**
 * Checks if a value is a valid number.
 *
 * @param {*} value - The value to be checked.
 * @returns {boolean} - Returns `true` if the value is a valid number, otherwise `false`.
 */
const isNumber = value => typeof value === 'number' && !Number.isNaN(value);

/**
 * Returns a string representing a number in fixed-point notation.
 *
 * @param {*} value - The value to round
 * @param {number} decimal - Number of decimal places
 * @returns {*} - Returns a number or the string "--"
 */
const getToFixedValue = (value, decimal = 1) => {
  const float = parseFloatDefault(value);
  return !isNullUndefinedOrEmpty(float) ? float.toFixed(decimal) : '--';
};

/**
 * Attaches a click outside event listener to the specified element.
 * Calls the provided handler function when a click event occurs outside the element.
 *
 * @param {React.RefObject} ref - The reference to the element to attach the event listener to.
 * @param {Function} handler - The function to be called when a click event occurs outside the element.
 */
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

const areAlarmsAndAlertsFilterValuesEmpty = (...values) => values.some(value => isNullUndefinedOrEmpty(value));

export {
  generateId,
  isSuccess,
  isLoading,
  isError,
  getMachineStatusProps,
  getSensorProps,
  getMachineType,
  getListWithoutDefaultValue,
  getMetricsTimeFrame,
  getMillGrindingTechnology,
  getMillInnerLinerMaterial,
  getMillBeadMaterialType,
  getBatchRecordTimeFrame,
  getMachineErrorTimeFrame,
  getSensorPropsFromType,
  getSensorStatusProps,
  getRoundedValue,
  getUnit,
  getActiveUsersAsOptions,
  getCommonLoadingState,
  getUserRole,
  getDialCodeFromCountryCode,
  getEvery15Min,
  isNullOrUndefined,
  isNullUndefinedOrEmpty,
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isCustomSensor,
  getSensorPropsFromMetric,
  getNDecimalPlaces,
  getCustomSensorId,
  getSensorValue,
  getSensorValueGraph,
  getSensorValueYAxis,
  getSensorUnit,
  getSensorName,
  parseFloatDefault,
  getChangeNumberHandler,
  getChangeFloatHandler,
  getCustomSensorName,
  getUserFeature,
  getUserDateFormat,
  getRoundedDecimalPlaceSensorValue,
  concatDates,
  isStringNumeric,
  timeToString,
  getInitials,
  isStaticSensor,
  getDecimalSeparator,
  getCurrentGMT,
  getUserLocale,
  getChartDataNullNormalize,
  getRoundedChartData,
  deepCopy,
  arraysEqual,
  isNumber,
  useClickOutside,
  areAlarmsAndAlertsFilterValuesEmpty,
  getToFixedValue
};
