/* eslint-disable */
import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import moment from 'moment';

import { TimePicker } from '@mui/x-date-pickers';
import { Button, Container, Divider, MenuItem, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, FormElements, NotifyTypo, useResolutionCheck, NewDatePicker } from 'web-components';

import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { COLOR_PRIMARY, MENU_ITEM_BORDER, PRIMARY_LIGHT_BACKGROUND } from '../../attrs/colors';
import { loadComparisonData, loadMachineStatusMetrics, loadSelectedSensorHistory } from '../../redux/machines/actions';
import { getTimeWindow } from '../../redux/ui/settings/selectors';
import { getMetricsTimeFrame } from '../../helpers/utils';

const TimeSelection = ({ machineId, sensorType, sensorTypes }) => {
  const { t } = useTranslation();
  const { isDesktop } = useResolutionCheck();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const timeWindow = useSelector(getTimeWindow);

  const [localTimeWindow, setLocalTimeWindow] = useState({
    from: `${timeWindow.from}T00:00:00Z`,
    to: `${timeWindow.to}T23:59:00Z`
  });

  const [localTime, setLocalTime] = useState(moment(localTimeWindow.from).format('HH:mm'));

  const [StartTime, setStartTime] = useState(null);
  const [EndTime, setEndTime] = useState(null);

  const getSelectedTimeWindow = time =>
    `${moment.utc(time.from).format('D MMM')}, ${moment.utc(time.from).format('HH:mm')} - ${moment
      .utc(time.to)
      .format('D MMM')}, ${moment.utc(time.to).format('HH:mm')}`;

  useEffect(() => {
    setLocalTimeWindow(timeWindow);
    setLocalTime(moment(timeWindow.from).format('HH:mm'));
  }, [timeWindow]);

  const handleChangeMode = e => {
    if (e.target.value === 3) {
      setSelected(2);
    } else {
      setSelected(e.target.value);
    }
  };

  const handleLoadTimeFrame = timeFrame => {
    dispatch(loadMachineStatusMetrics(machineId, getMetricsTimeFrame(timeFrame), timeFrame));
    if (sensorType) {
      dispatch(loadSelectedSensorHistory(machineId, sensorType, getMetricsTimeFrame(timeFrame), timeFrame));
    }
    if (sensorTypes) {
      dispatch(loadComparisonData(sensorTypes, getMetricsTimeFrame(timeFrame), timeFrame));
    }
  };

  const TIME_SELECTION_OPTIONS = [
    {
      id: 0,
      label: 'last8hours',
      onClick: () => handleLoadTimeFrame(8)
    },
    {
      id: 1,
      label: 'last24hours',
      onClick: () => handleLoadTimeFrame(24)
    },
    {
      id: 2,
      label: 'custom_date',
      onClick: () => setOpenModal(true)
    }
  ];

  const handleDateChange = value => {
    const startTime = localTimeWindow.from.split('T')[1] || '00:00:00Z';
    const endTime = localTimeWindow.to.split('T')[1] || '23:59:00Z';

    const startDateLocal = moment(value?.startDate).format('YYYY-MM-DD');
    let endDateLocal;

    if (value?.endDate) {
      endDateLocal = moment(value.endDate).format('YYYY-MM-DD');
    } else {
      const tempEndDate = moment(value?.startDate).add(24, 'hours');
      endDateLocal = tempEndDate.format('YYYY-MM-DD');
    }

    setLocalTimeWindow({
      from: `${startDateLocal}T${startTime}`,
      to: `${endDateLocal}T${endTime}`
    });
  };

  const handleSave = () => {
    dispatch(loadMachineStatusMetrics(machineId, localTimeWindow, false));
    if (sensorType) {
      dispatch(loadSelectedSensorHistory(machineId, sensorType, localTimeWindow, false));
    }
    if (sensorTypes) {
      dispatch(loadComparisonData(sensorTypes, localTimeWindow, false));
    }
    setOpenModal(false);
  };

  const handleStartTimeChange = newValue => {
    const formattedTime = newValue ? newValue.format('HH:mm') : '00:00';
    setLocalTimeWindow(prevState => ({
      ...prevState,
      from: `${prevState.from.split('T')[0]}T${formattedTime}:00Z`
    }));
  };

  const handleEndTimeChange = newValue => {
    const formattedTime = newValue ? newValue.format('HH:mm') : '23:59';
    setLocalTimeWindow(prevState => ({
      ...prevState,
      to: `${prevState.to.split('T')[0]}T${formattedTime}:00Z`
    }));
  };

  return (
    <>
      <DateTimeProvider>
        <Dialog fullScreen={!isDesktop} open={openModal}>
          <Dialog.Header highlightColor={COLOR_PRIMARY} onClose={() => setOpenModal(false)}>
            {t('dashboard.custom.time_selection.title')}
          </Dialog.Header>
          <Dialog.SubHeader>{t('dashboard.custom.time_selection.subtitle')}</Dialog.SubHeader>
          <Dialog.Body>
            <Container
              disableGutters
              maxWidth="sm"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <NewDatePicker HandleDates={handleDateChange} maxDays={6} />
              <Divider style={{ width: '310px', margin: '1rem 0' }} />
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                  <TimePicker
                    label="Start time"
                    ampm={false}
                    format="HH:mm"
                    value={StartTime}
                    onChange={handleStartTimeChange}
                    slotProps={{
                      textField: {
                        variant: 'filled'
                      }
                    }}
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TimePicker
                    label="Final time"
                    ampm={false}
                    format="HH:mm"
                    value={EndTime}
                    onChange={handleEndTimeChange}
                    slotProps={{
                      textField: {
                        variant: 'filled'
                      }
                    }}
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  background: PRIMARY_LIGHT_BACKGROUND,
                  minWidth: '350px',
                  maxWidth: '350px',
                  padding: '.8rem',
                  marginTop: '2rem',
                  marginBottom: '2rem'
                }}
              >
                <NotifyTypo.InfoText component="p">
                  {t('dashboard.custom.time_selection.info_box_1')}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1 component="p" style={{ fontWeight: 500 }}>
                  {getSelectedTimeWindow(localTimeWindow)}
                </NotifyTypo.Body1>
                <NotifyTypo.InfoText component="p">
                  {t('dashboard.custom.time_selection.info_box_2')}
                </NotifyTypo.InfoText>
              </div>
            </Container>
          </Dialog.Body>
          <Dialog.Actions>
            <Button onClick={() => setOpenModal(false)}>{t('dashboard.custom.cancel')}</Button>
            <Button
              disabled={localTimeWindow.from === timeWindow.from}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {t('dashboard.custom.save')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </DateTimeProvider>
      <FormElements.Select
        value={selected}
        native={false}
        handleChange={handleChangeMode}
        label={t(`dashboard.custom.time_selection.time_period`)}
        name="time-selection"
        id="time-selection"
        size="small"
        MenuProps={{
          elevation: 2
        }}
        style={{
          width: 280,
          maxWidth: 280
        }}
      >
        {TIME_SELECTION_OPTIONS.map(item => (
          <MenuItem
            style={{ height: 48, border: `1px solid ${MENU_ITEM_BORDER}` }}
            key={item.id}
            selected={item.id === selected}
            value={item.id}
            onClick={() => (item.onClick ? item.onClick() : {})}
          >
            {selected === 2 && item.id === selected
              ? getSelectedTimeWindow(timeWindow)
              : t(`dashboard.custom.time_selection.${item.label}`)}
          </MenuItem>
        ))}
        {selected === 2 && (
          <MenuItem
            onClick={() => setOpenModal(true)}
            style={{ height: 48, border: `1px solid ${MENU_ITEM_BORDER}` }}
            key={3}
            value={3}
          >
            {t(`dashboard.custom.time_selection.custom_date`)}
          </MenuItem>
        )}
      </FormElements.Select>
    </>
  );
};

TimeSelection.propTypes = {
  machineId: T.string.isRequired,
  sensorType: T.string,
  sensorTypes: T.arrayOf(T.string)
};

TimeSelection.defaultProps = {
  sensorType: null,
  sensorTypes: null
};

export default TimeSelection;
