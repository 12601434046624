import React, { forwardRef } from 'react';
import T from 'prop-types';
import { NotifyIcon, useResolutionCheck } from 'web-components';
import NotifyTypo from 'web-components/build/NotifyTypo';
import { StyledSelectedButtonWithIconAndSubTitle } from './elements';

const ButtonWithIconAndSubTitle = forwardRef(
  ({ placeholderMenu, onClick, iconName, title, subtitle, ...props }, ref) => {
    const { isDesktop } = useResolutionCheck();

    const additionalCardProps = onClick ? { onClick, clickable: 'true' } : {};

    return (
      <StyledSelectedButtonWithIconAndSubTitle
        ref={ref}
        elevation={3}
        data-selector="placeholder-sensor-card"
        placeholder="true"
        mobile={isDesktop ? 'false' : 'true'}
        {...additionalCardProps}
        {...props}
      >
        <NotifyIcon
          color="primary"
          iconName={iconName}
          fontSize="default"
          style={{ marginRight: '.5rem', fontSize: '40px' }}
        />
        <div style={{ flex: 1 }}>
          <NotifyTypo.Heading2 color="secundary">{title}</NotifyTypo.Heading2>
          <NotifyTypo.Subtitle2 color="secundary">{subtitle}</NotifyTypo.Subtitle2>
        </div>
      </StyledSelectedButtonWithIconAndSubTitle>
    );
  }
);

ButtonWithIconAndSubTitle.propTypes = {
  sensor: T.shape({
    mapValue: T.string,
    name: T.string,
    type: T.string,
    status: T.string
  }),
  placeholderMenu: T.bool,
  onClick: T.func,
  iconName: T.string,
  title: T.string,
  subtitle: T.string
};

ButtonWithIconAndSubTitle.defaultProps = {
  placeholderMenu: false,
  onClick: null,
  sensor: null,
  iconName: '',
  title: '',
  subtitle: ''
};

export default ButtonWithIconAndSubTitle;
