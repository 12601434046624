import { put, takeLatest, select, all } from 'redux-saga/effects';
import compose from 'lodash/fp/compose';

import * as constants from './constants';
import { loadUserList, updateUser, deleteUser, inviteUser } from '../../api/user';
import { actionStatus, statusAction, getError } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { getActiveOrganizationId } from '../user/selectors';
import { ROLE_OPERATOR } from '../../attrs/roles';
import * as actions from './actions';

function* enrichUserWithRole(data) {
  const organizationId = yield select(compose(getActiveOrganizationId));

  const enrichedData = {
    ...data,
    role: data.organizations[organizationId] || ROLE_OPERATOR
  };

  return enrichedData;
}

function* handleLoadUsers() {
  const organizationId = yield select(compose(getActiveOrganizationId));

  yield put(statusAction(constants.LOAD_USERS, actionStatus.START));
  try {
    const { data } = yield loadUserList(organizationId);
    const enrichedData = yield all(data.map(item => enrichUserWithRole(item)));

    yield put(statusAction(constants.LOAD_USERS, actionStatus.SUCCESS, { payload: enrichedData }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_USERS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleUpdateUserRole({ userId, values }) {
  const organizationId = yield select(compose(getActiveOrganizationId));

  const progressedValues = {
    ...values,
    organizations: {
      ...values.organizations,
      [organizationId]: values.role
    }
  };

  yield put(actions.updateUserById(userId, progressedValues));
}

function* handleUpdateUserById({ userId, values }) {
  yield put(statusAction(constants.UPDATE_USER_BY_ID, actionStatus.START));

  try {
    const { id, ...filteredValues } = values;
    const { data } = yield updateUser(userId, filteredValues);

    const enrichedData = yield enrichUserWithRole(data);

    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.SUCCESS, {
        payload: enrichedData
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleDeleteUserById({ userId }) {
  if (userId) {
    yield put(statusAction(constants.DELETE_USER_BY_ID, actionStatus.START));

    const organizationId = yield select(compose(getActiveOrganizationId));

    try {
      yield deleteUser(userId, organizationId);

      yield put(statusAction(constants.DELETE_USER_BY_ID, actionStatus.SUCCESS, { userId }));

      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'form.users.success.delete',
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.DELETE_USER_BY_ID, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.users.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

function* handleInviteUser({ values }) {
  const organizationId = yield select(compose(getActiveOrganizationId));

  yield put(statusAction(constants.INVITE_USER, actionStatus.START));

  try {
    const enrichedValues = {
      ...values,
      'e-mail': values.email,
      organization_id: organizationId
    };

    const { data } = yield inviteUser(enrichedValues);

    yield put(statusAction(constants.INVITE_USER, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.users.success.invite',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
    yield put(actions.loadUsers());
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_USERS, handleLoadUsers);
  yield takeLatest(constants.UPDATE_USER_ROLE, handleUpdateUserRole);
  yield takeLatest(constants.UPDATE_USER_BY_ID, handleUpdateUserById);
  yield takeLatest(constants.DELETE_USER_BY_ID, handleDeleteUserById);
  yield takeLatest(constants.INVITE_USER, handleInviteUser);
}
