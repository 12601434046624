import { put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import compose from 'lodash/fp/compose';

import * as constants from './constants';
import { loadUser, updateUser, resetPassword } from '../../api/user';
import { actionStatus, statusAction, getError } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { getOrganization } from '../../api/organization';
import { getUser, getData } from '../rootSelectors';
import { getUserInfo, getActiveOrganization, getUserId } from './selectors';
import i18n from '../../i18n';

function* handleLoadUser({ id }) {
  let userId = id;
  if (!userId) {
    userId = yield select(compose(getUserId, getData, getUserInfo, getUser));
  }

  if (userId) {
    yield put(statusAction(constants.LOAD_USER, actionStatus.START));

    try {
      const { data } = yield loadUser(userId);
      // change language based on user profile
      if (data && data.language && i18n.language !== data.language) {
        i18n.changeLanguage(data.language);
      }

      let enrichedData = data;
      const activeOrganizationId = Object.keys(enrichedData.organizations)[0];
      const activeUserRole = enrichedData.organizations[activeOrganizationId];

      enrichedData = {
        ...enrichedData,
        organization: {
          id: activeOrganizationId,
          userRole: activeUserRole
        }
      };

      yield put(statusAction(constants.LOAD_USER, actionStatus.SUCCESS, { payload: enrichedData }));
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.LOAD_USER, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.user.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

function* handleUpdateUser({ userId, values }) {
  yield put(statusAction(constants.UPDATE_USER, actionStatus.START));

  try {
    const { id, ...filteredValues } = values;
    const { data } = yield updateUser(userId, filteredValues);

    let enrichedData = data;
    const activeOrganizationId = Object.keys(enrichedData.organizations)[0];
    const activeUserRole = enrichedData.organizations[activeOrganizationId];

    enrichedData = {
      ...enrichedData,
      organization: {
        id: activeOrganizationId,
        userRole: activeUserRole
      }
    };

    yield put(statusAction(constants.UPDATE_USER, actionStatus.SUCCESS, { payload: enrichedData }));

    // change language based on user profile
    if (data && data.language && i18n.language !== data.language) {
      i18n.changeLanguage(data.language);
    }
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.user.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleGetOrganization() {
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));

  yield put(statusAction(constants.GET_ORGANIZATION, actionStatus.START));
  try {
    const { data } = yield getOrganization(organization.id);

    yield put(statusAction(constants.GET_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GET_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organization.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleResetPassword({ values }) {
  yield put(statusAction(constants.RESET_PASSWORD, actionStatus.START));
  try {
    yield resetPassword(values);

    yield put(statusAction(constants.RESET_PASSWORD, actionStatus.SUCCESS));
    yield put(push('/dashboard'));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.RESET_PASSWORD, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.user.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.GET_ORGANIZATION, handleGetOrganization);
  yield takeLatest(constants.LOAD_USER, handleLoadUser);
  yield takeLatest(constants.UPDATE_USER, handleUpdateUser);
  yield takeLatest(constants.RESET_PASSWORD, handleResetPassword);
}
