import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NewDatePicker, NewDialog } from 'web-components';
import { Box, Stack, TextField } from '@mui/material';
import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

/**
 * Custom dialog to select a date range and time range with validations
 * ATTENTION: This is implemented locally because systems use diferent date providers
 * @param open<caption>Define if the Dialog will be open
 * @param handleClose<caption>Handle close method for default actions
 * @param title<caption>Title of Dialog
 * @param subTitle<caption>Subtitle of Dialog
 * @param startTimeCaption<caption>Start time caption
 * @param endTimeCaption<caption>End time caption
 * @param cancelCaption<caption>Cancel button caption
 * @param okCaption<caption>Save button caption
 * @param setValues<caption>Set values got from the dialog
 * @param handleApply<caption>Extra actions to be executed when user clicks on apply date
 * @param showTimePicker<caption>Can pass false to hide time pickers
 * @param maxDays<caption>Max days to be selected
 * @returns CustomDateDialog<caption>@type {NewDialog}
 */
function CustomDateDialog({
  open,
  handleClose,
  title,
  subTitle,
  startTimeCaption,
  endTimeCaption,
  cancelCaption,
  okCaption,
  setValues,
  handleApply,
  showTimePicker,
  maxDays
}) {
  // workaround to save dates inside NewDatePicker
  const [savedDates, setSavedDates] = useState();

  const [startDate, setStartDate] = useState(null);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);

  const [startTime, setStartTime] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
  const [endTime, setEndTime] = useState(moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 }));
  const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);

  const handleDateChange = value => {
    setSavedDates(value);

    setStartDate(value?.startDate);
    if (value?.startDate && value?.startDate.isValid()) {
      setIsInvalidStartDate(false);
    } else {
      setIsInvalidStartDate(true);
    }

    setEndDate(value?.endDate);
    if (value?.endDate && value?.endDate.isValid()) {
      setIsInvalidEndDate(false);
    } else {
      setIsInvalidEndDate(true);
    }
  };

  const handleStartTime = date => {
    setStartTime(date);
    if (date && date.isValid()) {
      setIsInvalidStartTime(false);
    } else {
      setIsInvalidStartTime(true);
    }
  };

  const isSameDay = (date1, date2) => moment(date1).isSame(date2, 'day');

  const isTimeGreater = (time1, time2) => moment(time1).isAfter(time2);

  /**
   * Checks if the provided date is a valid end time.
   * Calendar doesn't allow to select end date smaller then start date
   * so just checking if start date and end date are different
   * or if they are the same but start time is smaller then end time
   * @param {Date} date - The date to check.
   * @returns {boolean} - Returns true if the date is a valid end time, false otherwise.
   */
  const isEndTimeValid = date =>
    !isSameDay(startDate, endDate) || (isSameDay(startDate, endDate) && isTimeGreater(date, startTime));

  const handleEndTime = date => {
    setEndTime(date);
    if (date && date.isValid() && isEndTimeValid(date)) {
      setIsInvalidEndTime(false);
    } else {
      setIsInvalidEndTime(true);
    }
  };

  function isValidDateRange() {
    return startDate && !isInvalidStartDate && endDate && !isInvalidEndDate;
  }

  function isValidTimeRange() {
    return showTimePicker ? startTime && !isInvalidStartTime && endTime && !isInvalidEndTime : true;
  }

  function canSave() {
    return isValidDateRange() && isValidTimeRange();
  }

  const handleOk = () => {
    const newStartTime = startTime && startTime.isValid() ? startTime.format('HH:mm') : '00:00';
    const newStartDateTime = new Date(`${startDate.format('YYYY-MM-DD')}T${newStartTime}`);
    const newStartDateTimeText = newStartDateTime.toISOString();

    const newEndTime = endTime && endTime.isValid() ? endTime.format('HH:mm') : '23:59';
    const newFinalDateTime = new Date(`${endDate.format('YYYY-MM-DD')}T${newEndTime}`);
    const newFinalDateTimeText = newFinalDateTime.toISOString();

    // with this values you can do whatever you want
    setValues(newStartDateTimeText, newFinalDateTimeText, newStartDateTime, newFinalDateTime);

    handleApply();
    handleClose();
  };

  const content = (
    <Box
      sx={{
        maxWidth: 'sm',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mx: 'auto',
        '& > * + *': {
          // Applies top margin to all direct children after the first
          mt: 2
        }
      }}
    >
      <DateTimeProvider>
        <>
          <NewDatePicker HandleDates={handleDateChange} savedDates={savedDates} maxDays={maxDays} blockAfterToday />
          {showTimePicker && (
            <Stack direction="row" spacing={2}>
              <TimePicker
                id="start-time-picker"
                label={startTimeCaption}
                ampm={false}
                format="HH:mm"
                value={startTime}
                onChange={handleStartTime}
                slotProps={{
                  textField: {
                    variant: 'filled'
                  }
                }}
                sx={{ maxWidth: '180px' }}
                renderInput={params => <TextField {...params} error={isInvalidStartTime} />}
              />
              <TimePicker
                id="end-time-picker"
                label={endTimeCaption}
                ampm={false}
                format="HH:mm"
                value={endTime}
                onChange={handleEndTime}
                slotProps={{
                  textField: {
                    variant: 'filled'
                  }
                }}
                sx={{ maxWidth: '180px' }}
                renderInput={params => <TextField {...params} error={isInvalidEndTime} />}
              />
            </Stack>
          )}
        </>
      </DateTimeProvider>
    </Box>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleOk={handleOk}
      title={title}
      subTitle={subTitle}
      content={content}
      cancelCaption={cancelCaption}
      okCaption={okCaption}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minWidth="450px"
      maxWidth="98%"
    />
  );
}

CustomDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  startTimeCaption: PropTypes.string.isRequired,
  endTimeCaption: PropTypes.string.isRequired,
  cancelCaption: PropTypes.string.isRequired,
  okCaption: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  handleApply: PropTypes.func,
  showTimePicker: PropTypes.bool,
  maxDays: PropTypes.number
};

CustomDateDialog.defaultProps = {
  subTitle: '',
  handleApply: () => null,
  showTimePicker: true,
  maxDays: 0
};

export default CustomDateDialog;
