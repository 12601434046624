import React, { useLayoutEffect } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Button, CircularProgress, useMediaQuery } from '@mui/material';
import { NotifyTypo, DialCodes, FormElements } from 'web-components';

import { resetUserUpdateLoadingState, updateUser } from '../../../redux/user/actions';
import { AccountUpdateSchema } from '../../../attrs/formValidation';
import { getDialCodeFromCountryCode, isError, isLoading } from '../../../helpers/utils';
import RouteLeavingCheck from '../../elements/RouteLeavingCheck';
import { LANGUAGES } from '../../../attrs/languages';
import FormAlert from '../FormAlert';
import { ContainerForm, ButtonContainer } from './elements';

const AccountForm = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateLoadingState } = useSelector(state => state.user.user);

  const isSubmitting = isLoading(updateLoadingState.status);

  const isMobile = useMediaQuery('max-width:425px');

  const updateUserInformation = values => {
    dispatch(updateUser(user.id, values));
  };

  // Resets loading/error state
  useLayoutEffect(
    () => () => {
      dispatch(resetUserUpdateLoadingState());
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={{
        ...user,
        phone: {
          ...user.phone,
          country: ((user || {}).phone || {}).country || DialCodes[0].code
        }
      }}
      validationSchema={AccountUpdateSchema}
      onSubmit={values => updateUserInformation(values)}
      enableReinitialize
    >
      {({ values, dirty, handleReset, handleChange, handleSubmit, handleBlur, errors, touched }) => {
        const selectedDialCode = getDialCodeFromCountryCode(values.phone.country);

        return (
          <>
            <ContainerForm maxWidth="sm">
              <NotifyTypo.InfoText align="center" gutterBottom style={{ marginBottom: '1rem' }}>
                ({t('profile.account_register_info_text')}
                <b>{user['e-mail']}</b>)
              </NotifyTypo.InfoText>
              <NotifyTypo.Heading2>{t('profile.personal_information')}</NotifyTypo.Heading2>
              <RouteLeavingCheck when={dirty} />
              <form>
                <FormElements.TextField
                  label={t('profile.first_name')}
                  fullWidth
                  id="firstName"
                  margin="normal"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  required
                  error={errors.first_name && touched.first_name}
                  helperText={errors.first_name && touched.first_name && t(`form.validate.${errors.first_name}`)}
                />
                <FormElements.TextField
                  label={t('profile.last_name')}
                  fullWidth
                  id="lastName"
                  margin="normal"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={errors.last_name && touched.last_name}
                  helperText={errors.last_name && touched.last_name && t(`form.validate.${errors.last_name}`)}
                />
                <FormElements.Select
                  value={values.phone.country}
                  fullWidth
                  handleChange={handleChange}
                  label={t('profile.country_code')}
                  name="phone.country"
                  id="phoneCountry"
                >
                  {DialCodes.map(country => (
                    <option key={country.code} value={country.code}>
                      {`${t(country.name)} (${country.dial_code})`}
                    </option>
                  ))}
                </FormElements.Select>
                <FormElements.TextField
                  label={t('profile.phone')}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{selectedDialCode}</InputAdornment>
                  }}
                  id="phoneNumber"
                  margin="normal"
                  name="phone.number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone.number}
                  required
                  error={((errors || {}).phone || {}).number && ((touched || {}).phone || {}).number}
                  helperText={
                    ((errors || {}).phone || {}).number &&
                    ((touched || {}).phone || {}).number &&
                    t(`form.validate.${errors.phone.number}`)
                  }
                />
                <NotifyTypo.Heading2 gutterBottom style={{ marginTop: '2rem' }}>
                  {t('profile.account_settings')}
                </NotifyTypo.Heading2>
                <FormElements.Select
                  value={values.language}
                  handleChange={handleChange}
                  fullWidth
                  label={t('profile.languages.language')}
                  name="language"
                  id="language"
                >
                  {LANGUAGES.map(language => (
                    <option key={language.value} value={language.value}>
                      {t(language.label)}
                    </option>
                  ))}
                </FormElements.Select>
                <Button
                  component={Link}
                  variant="outlined"
                  size="large"
                  style={{
                    marginTop: '1.5rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem'
                  }}
                  to="/account/reset-password"
                >
                  {t('profile.change_password')}
                </Button>
                {isError(updateLoadingState.status) && <FormAlert message={t('errors.form_submit')} />}
              </form>
            </ContainerForm>
            <ButtonContainer container>
              <Button
                variant="text"
                color="primary"
                disabled={!dirty || isSubmitting}
                onClick={handleReset}
                fullWidth={isMobile}
              >
                {t('form.cancel')}
              </Button>
              <Button
                disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                endIcon={isSubmitting && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="submit"
                fullWidth={isMobile}
              >
                {t('form.apply')}
              </Button>
            </ButtonContainer>
          </>
        );
      }}
    </Formik>
  );
};

AccountForm.propTypes = {
  user: T.shape({
    id: T.string,
    phone: T.shape({ country: T.string, number: T.string }),
    'e-mail': T.string
  }).isRequired
};

export default AccountForm;
